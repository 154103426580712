<template>
  <div>
    <link
      crossorigin="crossorigin"
      href="https://fonts.gstatic.com"
      rel="preconnect"
    />

    <link
      as="style"
      href="https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,700;1,400&family=Montserrat:wght@300;400;500;600;700&display=swap"
      rel="preload"
    />

    <link
      href="https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,700;1,400&family=Montserrat:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    />

    <link
      rel="stylesheet"
      href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
    />

    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/combine/npm/@glidejs/glide@3.4.1/dist/css/glide.core.min.css,npm/@glidejs/glide@3.4.1/dist/css/glide.theme.min.css"
    />

    <link
      crossorigin="anonymous"
      href="/assets/styles/main.min.css"
      media="screen"
      rel="stylesheet"
    />

    <div
      id="main"
      x-data="{mobileMenu: false}"
      :class="{
        'relative max-h-screen overflow-hidden': mobileMenu,
      }"
    >
      <div>
        <div
          class="
            absolute
            inset-0
            h-24
            mx-auto
            z-20
            bg-bottom bg-cover bg-no-repeat
          "
          style="background-image: url(/assets/img/bg-cta-desktop.jpg)"
        >
          <div class="absolute inset-0 bg-dark w-full"></div>
          <div class="z-40 relative">
            <div class="container flex justify-between items-center py-4">
              <router-link :to="'/'">
                <img :src="`${URL}${logo}`" class="" alt="logo" />
              </router-link>

              <div class="flex">
                <ul class="justify-between items-center pt-1 hidden lg:flex">
                  <li
                    class="border-white py-1 pl-8"
                    v-for="(v, index) in sort_menu"
                    :key="index"
                  >
                    <a
                      :href="v.url"
                      class="
                        font-body font-medium
                        text-base text-white
                        hover:text-purple
                        transition
                        duration-300
                        uppercase
                        tracking-wide
                        block
                        cursor-pointer
                      "
                      >{{ v.name }}</a
                    >
                  </li>
                </ul>
                <div class="flex items-center">
                  <span class="lg:hidden" @click="mobileMenu = true">
                    <i
                      class="
                        bx bx-menu
                        text-white
                        md:text-3xl
                        text-2xl
                        pr-1
                        hover:cursor-pointer
                      "
                    ></i>
                  </span>

                  <a href="/search" class="flex items-center pl-4">
                    <i class="bx bx-search-alt text-white text-xl px-2"></i>
                  </a>

                  <a
                    href="?locale=th"
                    class="flex items-center pl-4 text-white"
                  >
                    TH
                  </a>
                  <a
                    href="?locale=en"
                    class="flex items-center pl-4 text-white"
                  >
                    EN
                  </a>
                  <div
                    class="
                      bg-primary
                      hover:bg-white
                      transition
                      duration-300
                      shadow
                      hover:shadow-xs
                      py-1
                      flex
                      items-center
                      rounded-full
                      group
                      ml-5
                      relative
                    "
                    :class="googleUserData.email ? 'w-16 px-1' : 'px-2'"
                  >
                    <div
                      v-if="googleUserData.email"
                      class="overflow-hidden rounded-full"
                    >
                      <a href="https://stat.072.sh/">
                        <img :src="googleUserData.picture" class=""
                      /></a>
                    </div>
                    <a
                      v-else
                      class="cursor-pointer"
                      href="https://072.sh/login/do"
                    >
                      <i
                        class="
                          bx
                          bxl-google
                          text-white
                          group-hover:text-secondary
                          text-xl
                          rounded-full
                        "
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          fixed
          inset-0
          bg-black bg-opacity-70
          z-50
          flex
          opacity-0
          pointer-events-none
          transition-opacity
        "
        :class="{ 'pointer-events-auto opacity-100': mobileMenu }"
      >
        <div class="bg-white w-2/3 sm:w-1/2 ml-auto min-h-screen">
          <div class="flex justify-end p-8">
            <span @click="mobileMenu = false" class="hover:cursor-pointer">
              <i class="bx bx-x text-secondary text-3xl"></i>
            </span>
          </div>
          <ul class="px-8">
            <li
              class="border-b border-neutrals-g02 py-1 pb-3 mb-3"
              v-for="(v, index) in sort_menu"
              :key="index"
            >
              <a
                :href="v.url"
                class="
                  font-body font-medium
                  text-base text-neutrals-g03
                  uppercase
                  tracking-wide
                "
                >{{ v.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      mobileMenu: false,
      URL: "https://create.072.sh",
      sort_menu: [],
      googleUserData: {},
      access_token: "",
    };
  },
  props: ["logo", "menu"],
  beforeMount() {
    let sortmenu = this.menu;
    this.sort_menu = sortmenu.sort((a, b) => {
      return a.order - b.order;
    });
  },
  computed: {},
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.access_token =
      urlParams.has("access_token") && urlParams.get("access_token")
        ? urlParams.get("access_token")
        : "";
    if (this.access_token) {
      this.getprofile();
    } else {
      let logindata = JSON.parse(this.getCookie("072login")) || null;
      if (logindata) {
        this.googleUserData = logindata;
        this.$emit("logindata", logindata);
      }
    }
  },
  methods: {
    getprofile: async function () {
      let Gdata = await axios.get(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" +
          this.access_token
      );
      this.googleUserData = Gdata.data;
      let Sdata = await axios.get(
        "https://create.072.sh/api/auth/google/callback?access_token=" +
          this.access_token
      );
      this.googleUserData.userID = Sdata.data.user.id;
      this.googleUserData.userJWT = Sdata.data.jwt;
      this.$emit("logindata", this.googleUserData);
      this.setCookie("072login", JSON.stringify(this.googleUserData), 14);
    },
    setCookie: function (name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        name + "=" + (value || "") + expires + "; path=/;domain=.072.sh;";
    },
    getCookie: function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
</style>