import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/index.vue";
import Blog from "../views/blog.vue";
import Post from "../views/post.vue";
import Page404 from "../views/404.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: Home,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/post/:id",
    name: "post",
    component: Post,
  },
  {
    path: "/404",
    name: "404",
    component: Page404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
});

export default router;
