<template>
  <div>
    <Header
      v-if="www.logo"
      :logo="www.logo?.data.attributes.url"
      :menu="www.menu"
    />

    <div class="bg-neutrals-l00 m-20">
      <div class="container pt-16 pb-48 lg:pb-40">
        <div class="flex flex-col xl:flex-row justify-between">
          <div class="xl:w-2/3">
            <h1 class="font-body font-semibold text-neutrals-g03 text-4xl">
              {{ blogMain.h1 }}
            </h1>

            <div
              class="
                flex flex-col
                lg:flex-row
                mt-8
                pb-8
                border-b border-neutrals-g01
              "
              v-for="(v, index) in blogs"
              :key="index"
            >
              <router-link
                :to="'/post/' + v.id"
                class="
                  w-full
                  lg:w-1/3
                  h-56
                  sm:h-80
                  lg:h-52
                  mr-8
                  rounded-lg
                  bg-cover bg-top
                  sm:bg-center
                  lg:bg-top
                  bg-no-repeat
                "
                :style="`background-image: url(https://create.072.sh${v.attributes.thumb.data.attributes.url})`"
              ></router-link>
              <div class="w-full lg:w-2/3 pt-6 lg:pt-0">
                <router-link
                  :to="'/post/' + v.id"
                  class="
                    font-body font-semibold
                    text-neutrals-g03
                    hover:text-secondary
                    transition
                    duration-300
                    text-xl
                  "
                  >{{ v.attributes.topic }}</router-link
                >

                <div class="flex items-center pt-2">
                  <span class="font-body text-xs text-neutrals-g02 pr-5">{{
                    v.attributes.publishedAt
                  }}</span>
                </div>
                <p class="font-body text-neutrals-g03 text-sm pt-2 md:pt-3">
                  {{ v.attributes.des }}
                </p>
                <router-link
                  :to="'/post/' + v.id"
                  class="
                    mt-5
                    inline-block
                    px-6
                    py-3
                    bg-white
                    hover:text-purple
                    font-body
                    text-neutrals-g03 text-sm
                    rounded-full
                    shadow
                    hover:shadow-md
                    mr-5
                    border border-primary
                    hover:border-purple
                    transition
                    duration-300
                  "
                >
                  {{ www.blog.button_text }}
                </router-link>
              </div>
            </div>
          </div>
          <!-- RIGHT -->
          <div
            class="
              w-full
              sm:w-3/5
              lg:w-2/5
              mx-auto
              xl:mx-0 xl:w-1/4
              group
              pt-20
              xl:pt-0
            "
          ></div>
        </div>
      </div>
    </div>

    <Subscribe v-if="www.subscribe" :sub="www.subscribe" />
    <FooterDetail v-if="www.footer" :footer="www.footer" />
    <Footer v-if="www.footer" :footer="www.footer" />
  </div>
</template>

<script>
import Header from "../components/HeaderTop.vue";

import Subscribe from "../components/SubscribeBox.vue";
import FooterDetail from "../components/FooterDetail.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "Blog",
  components: { Header, Subscribe, Footer, FooterDetail },
  data() {
    return {
      www: {},
      blogMain: {},
      blogs: {},
      locale: "en",
    };
  },
  async beforeCreate() {
    let urlParams = new URLSearchParams(window.location.search);
    let locale =
      urlParams.has("locale") && urlParams.get("locale")
        ? urlParams.get("locale")
        : "en";
    this.locale = locale;
    const qs = require("qs");
    const queryScene = qs.stringify(
      {
        populate: [
          "logo",
          "menu",
          "hero",
          "blog",
          "hero.image",
          "subscribe",
          "footer.social",
          "footer.center_image",
          "footer.contact",
          "footer.link",
          "copyright",
          "feature.advantage",
          "feature.advantage.image",
          "pagedetail",
        ],
        locale: locale,
      },
      {
        encodeValuesOnly: true,
      }
    );
    const www = await axios.get(`https://create.072.sh/api/www?${queryScene}`);
    this.www = www.data.data.attributes;

    const queryBlog = qs.stringify(
      {
        populate: ["*"],
        locale: locale,
      },
      {
        encodeValuesOnly: true,
      }
    );
    const blogMain = await axios.get(
      `https://create.072.sh/api/blog-main?${queryBlog}`
    );
    this.blogMain = blogMain.data.data.attributes;

    const queryBlogs = qs.stringify(
      {
        populate: ["*", "thumb"],
        locale: locale,
      },
      {
        encodeValuesOnly: true,
      }
    );
    const blogs = await axios.get(
      `https://create.072.sh/api/blogs?${queryBlogs}`
    );
    this.blogs = blogs.data.data;
    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector("head title");
    const keywordEl = document.querySelector('head meta[name="keywords"]');
    descEl.setAttribute("content", this.www.pagedetail.des);
    keywordEl.setAttribute("content", this.www.pagedetail.keywords);
    titleEl.textContent = this.www.pagedetail.title;
  },
  methods: {
    mkdate: function (pdate = new Date()) {
      const edate = pdate.split("T")[0].split("-");
      const date = new Date(edate[0], edate[1], edate[2]);
      const result = date.toLocaleDateString(this.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return result;
    },
  },
};
</script>
