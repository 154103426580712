import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    www: {},
  },
  getters: {
    wwww: (state) => () => {
      return state.www;
    },
  },
  mutations: {
    async www(state,locale = "en") {
      const qs = require("qs");
      const queryScene = qs.stringify(
        {
          populate: [
            "logo",
            "menu",
            "hero",
            "hero.image",
            "about",
            "about.image",
            "blog",
            "feature",
            "subscribe",
            "footer.social",
            "footer.center_image",
            "footer.contact",
            "footer.link",
            "copyright",
            "feature.advantage",
            "feature.advantage.image",
            "pagedetail",
          ],
          locale: locale,
        },
        {
          encodeValuesOnly: true,
        }
      );
      const www = await axios.get(
        `https://create.072.sh/api/www?${queryScene}`
      );
      let wwwData = www.data.data.attributes;
      state.www = wwwData;
    },
  },
});
