<template>
  <div class="bg-secondary">
    <div
      class="
        container
        flex flex-col
        sm:flex-row
        justify-between
        items-center
        py-6
      "
    >
      <ul class="flex flex-col sm:flex-row items-center">
        <li
          class="sm:pr-8 pb-3 sm:pb-0"
          v-for="(s, index) in footer.link"
          :key="index"
        >
          <a
            :href="s.url"
            class="
              font-body font-medium
              text-xs
              md:text-sm
              text-white
              hover:text-purple
              tracking-wide
              transition
              duration-300
            "
            >{{ s.name }}</a
          >
        </li>
      </ul>
      <div class="pt-4 sm:pt-0">
        <p
          class="
            font-body font-medium
            text-xs
            md:text-sm
            text-white
            tracking-wide
          "
        >
          © {{ new Date().getFullYear() }} {{ footer.copyright }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["footer"],
};
</script>

<style>
</style>