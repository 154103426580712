<template>
  <HeaderTop
    v-if="www.logo"
    :logo="www.logo?.data.attributes.url"
    :menu="www.menu"
    @logindata="getprofile"
  />
  <HeroApp v-if="www.hero" :hero="www.hero" :userid="userid" />
  <About v-if="www.about" :about="www.about" />
  <Blog v-if="www.blog" :blog="www.blog" :blogs="blog" />
  <Advantage v-if="www.feature" :feature="www.feature" />
  <FooterDetail v-if="www.footer" :footer="www.footer" />
  <Footer v-if="www.footer" :footer="www.footer" />
</template>

<script>
import HeroApp from "../components/HeroApp.vue";
import HeaderTop from "../components/HeaderTop.vue";
import About from "../components/About.vue";
import Advantage from "../components/Advantage.vue";
import Blog from "../components/Blog.vue";
import FooterDetail from "../components/FooterDetail.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
export default {
  title: "Shorten URL Shorten the search to the Meaning of Life - 072.sh",
  name: "App",
  components: {
    HeroApp,
    HeaderTop,
    About,
    Advantage,
    Blog,
    Footer,
    FooterDetail,
  },
  data() {
    return {
      www: {},
      blog: {},
      userid: 0,
    };
  },
  async beforeCreate() {
    let urlParams = new URLSearchParams(window.location.search);
    let locale =
      urlParams.has("locale") && urlParams.get("locale")
        ? urlParams.get("locale")
        : "en";
    const qs = require("qs");
    const queryScene = qs.stringify(
      {
        populate: [
          "logo",
          "menu",
          "hero",
          "hero.image",
          "about",
          "about.image",
          "blog",
          "feature",
          "subscribe",
          "footer.social",
          "footer.center_image",
          "footer.contact",
          "footer.link",
          "copyright",
          "feature.advantage",
          "feature.advantage.image",
          "pagedetail",
        ],
        locale: locale,
      },
      {
        encodeValuesOnly: true,
      }
    );
    const www = await axios.get(`https://create.072.sh/api/www?${queryScene}`);
    this.www = www.data.data.attributes;

    const blog = await axios.get(`https://create.072.sh/api/blogs?populate=*`);
    this.blog = blog.data.data;
    // const blog = await axios.get(
    //   `https://create.072.sh/api/blog-main?populate=*`
    // );
    // this.blog = blog.data.data.attributes;

    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector("head title");
    const keywordEl = document.querySelector('head meta[name="keywords"]');
    descEl.setAttribute("content", this.www.pagedetail.des);
    keywordEl.setAttribute("content", this.www.pagedetail.keywords);
    titleEl.textContent = this.www.pagedetail.title;
  },
  methods: {
    getprofile: function (e) {
      this.userid = e.userID;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
