<template>
  <div
    id="contact"
    class="bg-center bg-cover bg-no-repeat w-full h-full"
    style="background-image: url(/assets/img/bg-footer.jpg)"
  >
    <div class="container flex flex-col lg:flex-row justify-between py-10">
      <div class="block lg:hidden">
        <a href="/" class="mb-10 block">
          <img
            :src="`${URL_API}${footer.center_image.data.attributes.url}`"
            class="xl:h-24 relative -left-3 mx-auto"
            alt="logo"
          />
        </a>
      </div>
      <div class="lg:w-1/3 text-center lg:text-left">
        <span class="font-body font-semibold text-white text-lg">{{
          footer.about_h1
        }}</span>
        <div class="py-3 flex justify-center lg:justify-start">
          <img src="/assets/img/underline-white.svg" alt="underline" />
        </div>
        <div class="lg:pr-8 sm:w-1/2 lg:w-full mx-auto lg:mx-0">
          <p
            class="
              font-body font-medium
              text-md text-white
              tracking-wide
              leading-relaxed
            "
          >
            {{ footer.about_des }}
          </p>
        </div>
        <div class="flex items-center justify-center lg:justify-start pt-4">
          <a v-for="(s, index) in footer.social" :key="index" :href="s.url"
            ><i
              :class="'bxl-' + s.bx_icon"
              class="
                bx
                text-white
                opacity-100
                hover:opacity-75
                text-lg
                pr-2
                transition
                duration-300
              "
            ></i
          ></a>
        </div>
      </div>
      <div class="items-center hidden lg:flex">
        <a href="/" class="block -ml-16">
          <img
            :src="`${URL_API}${footer.center_image.data.attributes.formats.thumbnail.url}`"
            class="xl:h-16"
            alt="logo"
          />
        </a>
      </div>
      <div
        class="
          w-3/4
          md:w-11/12
          lg:w-1/3 lg:pl-8
          text-center
          lg:text-left
          pt-8
          lg:pt-0
          mx-auto
          lg:mx-0
        "
      >
        <span class="font-body font-semibold text-white text-lg">{{
          footer.about_h1
        }}</span>
        <div class="py-3 flex justify-center lg:justify-start">
          <img src="/assets/img/underline-white.svg" alt="underline" />
        </div>
        <div
          class="
            w-11/12
            sm:w-2/5
            md:w-1/3
            lg:w-full
            mx-auto
            lg:mx-0
            md:pl-4
            lg:pl-0
          "
        >
          <a
            v-for="(s, index) in footer.contact"
            :key="index"
            :href="s.url"
            class="
              flex
              pt-2
              opacity-100
              hover:opacity-75
              transition
              duration-300
            "
          >
            <i
              :class="'bx-' + s.bx_icon"
              class="bx text-white text-xl pr-4"
            ></i>
            <p
              class="
                font-body font-medium
                text-sm text-white
                tracking-wide
                leading-relaxed
                text-left
              "
            >
              {{ s.name }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["footer"],
};
</script>

<style>
</style>