<template>
  <div
    class="bg-bottom bg-cover bg-no-repeat w-full h-full"
    style="background-image: url(/assets/img/bg-hero.jpg)"
  >
    <div
      class="
        container
        pt-32
        sm:pt-44
        md:pt-20
        flex flex-col
        lg:flex-row
        justify-start
        lg:justify-between
        items-center
      "
    >
      <div class="lg:w-2/5 text-center lg:text-left md:pt-10">
        <h1
          class="
            font-body font-medium
            text-white text-4xl
            lg:text-5xl
            xl:text-6xl
            tracking-tight
          "
          v-html="hero.h1"
        ></h1>
        <p
          class="
            font-body font-light
            text-white text-lg
            lg:text-2xl
            xl:text-3xl
            tracking-wider
            leading-tight
            pt-5
          "
          v-html="hero.h2"
        ></p>
        <input
          type="text"
          id="search"
          v-model="url"
          :placeholder="hero.input_placeholder"
          class="
            w-10/12
            mt-8
            rounded-xl
            bg-transparent
            font-body font-medium
            text-md
            lg:text-2xl
            text-white
            tracking-wide
            focus:shadow-none
            placeholder-neutrals-l00
            p-4
            border-2
            rals-g02
            border-neutrals-g04
            lg:border-neutrals-g02
            focus:outline-none focus:border-primary focus:ring-0
          "
        />

        <div
          id="short_url"
          class="
            scale-0
            ease-in-out
            duration-300
            alert alert-info
            w-10/12
            mt-6
            bg-opacity-90
            text-white
            mx-auto
            lg:mx-0
            cursor-pointer
          "
          @click="clickCopy()"
        >
          <div class="flex-1 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
            <label
              class="cursor-pointer text-lg lg:text-2xl ml-4 text-center"
              @click="clickCopy()"
              >{{ short_url }}</label
            >
          </div>
        </div>

        <div
          id="error"
          class="
            scale-0
            ease-in-out
            duration-300
            alert alert-warning
            w-10/12
            -mt-14
            bg-opacity-90
            text-white
            mx-auto
            lg:mx-0
            cursor-pointer
          "
        >
          <div class="flex-1 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <label
              class="cursor-pointer text-lg lg:text-2xl ml-4 text-center"
              >{{ errorMessage }}</label
            >
          </div>
        </div>

        <div
          class="text-md text-blue-600 ml-2 hidden"
          id="short_url_detail"
          v-html="hero.after_short + ' ' + htmltoSeeStat"
        ></div>
        <div class="text-md text-blue-600 ml-2 hidden" id="show_copied">
          Copied !!!
        </div>

        <a
          @click="shorten(url)"
          class="
            hover:cursor-pointer
            py-4
            md:py-5
            px-8
            lg:px-10
            bg-white
            font-body font-medium
            text-secondary
            hover:text-purple
            text-base
            lg:text-xl
            tracking-wider
            uppercase
            rounded-full
            text-center
            inline-block
            mt-3
            lg:mt-10
            shadow-md
            hover:shadow
            transition
            duration-500
            border border-white
            hover:border-purple
          "
          >{{ hero.button_text }}</a
        >
      </div>
      <div class="w-full sm:w-5/6 lg:w-1/2 mt-10">
        <img
          :src="`${URL_API}${hero.image.data.attributes.url}`"
          alt="hero illustration"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import validUrl from "valid-url";
export default {
  props: ["hero", "userid"],
  data() {
    return {
      errorMessage: "",
      oldURL:'',
      URL_API: "https://create.072.sh",
      url: "",
      short_url: "",
      show_copied: false,
      htmltoSeeStat: "",
    };
  },
  mounted() {
    document.addEventListener("paste", async (e) => {
      let data = e.clipboardData.getData("text/plain");
      this.shorten(data);
    });
  },
  methods: {
    showErrorMessage: function (e) {
      this.errorMessage = e;
      let elShort = document.getElementById("short_url");
      let elShortD = document.getElementById("short_url_detail");
      let elerror = document.getElementById("error");
      elShort.classList.remove("animate-pulse", "scale-100");
      elShort.classList.add("animate-pulse", "scale-0");
      elShortD.classList.remove("block");
      elShortD.classList.add("hidden");
      elerror.classList.add("animate-pulse", "scale-100");
      setTimeout(() => {
        elerror.classList.remove("animate-pulse", "scale-100");
        elerror.classList.add("animate-pulse", "scale-0");
      }, 8000);
    },
    shorten: async function (url) {
      let pattUrl072 = new RegExp(
        "^((http|https)://)(www.)?(072.sh)(/.*)?",
        "g"
      );
      const data_comment = {
        url: url,
        userid: this.userid,
      };

      try {
        if(this.short_url && url == this.oldURL) throw new Error("Please Change URL");
        if (!validUrl.isUri(url)) throw new Error("Not Valid URL");
        if (pattUrl072.test(url))
          throw new Error("072.sh is not allowed to short");

        const response = await axios({
          method: "post",
          url: "/create",
          data: data_comment,
        });

        if (response.status != 200) throw new Error("Opps! " + isError);

        const isError = response.data?.error ?? null;
        if (isError) throw new Error(isError);

        this.url = response.data.url;
        this.oldURL = response.data.url;
        this.short_url = response.data.short_url;
        this.htmltoSeeStat = `<a href="https://stat.072.sh/${response.data.key}" target="_blank">https://stat.072.sh/${response.data.key}  <i class="bx bx-link-external"></i></a>`;
        this.copyToClipboard();
      } catch (e) {
        this.showErrorMessage(e);
        console.error("Error", e);
      }
    },
    clickCopy: function () {
      document.getElementById("show_copied").classList.remove("hidden");
      document.getElementById("show_copied").classList.add("block");
      this.copyToClipboard();
    },
    copyToClipboard: function () {
      this.$copyText(this.short_url).then(
        function () {
          document
            .getElementById("short_url")
            .classList.add("animate-pulse", "scale-100");
          document
            .getElementById("short_url_detail")
            .classList.remove("hidden");
          document.getElementById("short_url_detail").classList.add("block");
        },
        function (e) {
          console.log(e);
        }
      );
    },
  },
};
</script>

<style>
</style>