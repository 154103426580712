<template>
  <!-- Advantages -->

  <div class="relative" id="advantage">
    <div
      class="
        bg-cover bg-center bg-no-repeat
        w-128
        h-128
        absolute
        top-0
        left-0
        -ml-56
        mt-32
      "
      style="background-image: url(/assets/img/blog-ellipse.svg)"
    ></div>
    <div class="container pb-16 lg:pb-24 z-20 relative">
      <div class="text-center">
        <h4
          class="
            font-body font-medium
            text-primary text-base
            md:text-lg
            uppercase
          "
        >
          {{ feature.h2 }}
        </h4>
        <h2
          class="
            font-body font-bold
            text-neutrals-g03 text-3xl
            sm:text-4xl
            lg:text-5xl
            tracking-wider
          "
        >
          {{ feature.h1 }}
        </h2>
        <div class="pt-4 md:pt-6">
          <img
            src="/assets/img/underline-blue.svg"
            class="mx-auto"
            alt="underline"
          />
        </div>
      </div>
      <div
        class="
          grid grid-cols-1
          sm:grid-cols-2
          lg:grid-cols-3
          gap-8
          xl:gap-10
          pt-10
        "
      >
        <div
          class="px-3 py-8 text-center rounded-lg"
          v-for="(v, index) in feature.advantage"
          :key="index"
        >
          <div class="w-20 lg:w-24 h-20 lg:h-24 mx-auto">
            <img
              :src="`${URL_API}${v.image.data.attributes.url}`"
              alt="icon responsive"
            />
          </div>
          <h3
            class="
              font-body font-semibold
              text-neutrals-g03 text-xl
              tracking-wide
              pt-2
              block
            "
          >
            {{ v.name }}
          </h3>
          <p
            class="font-body text-neutrals-g03 text-lg leading-relaxed pt-6"
            v-html="v.des"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["feature"],
};
</script>

<style>
</style>