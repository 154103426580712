<template>
  <div
    class="
      bg-bottom bg-cover bg-no-repeat bg-cta
      w-full
      h-24
      md:h-20
      xl:h-32
      2xl:h-20
      relative
    "
  >
    <div class="container absolute inset-0">
      <div
        class="
          bg-center bg-cover bg-no-repeat
          w-full
          sm:w-5/6
          md:w-full
          mx-auto
          md:mx-0
          rounded-lg
          -mt-28
          md:-mt-16
          relative
          overflow-hidden
        "
        style="background-image: url(/assets/img/bg-newsletter.png)"
      >
        <div
          class="
            bg-center bg-cover bg-no-repeat
            w-40
            h-40
            absolute
            top-0
            left-0
            -mt-10
            -ml-8
            overflow-hidden
          "
          style="background-image: url(/assets/img/figure-ellipse.svg)"
        ></div>
        <div
          class="
            w-11/12
            lg:w-5/6
            mx-auto
            flex flex-col
            md:flex-row
            justify-between
            py-8
            sm:py-10
            lg:py-8
          "
        >
          <div class="text-center md:text-left">
            <span
              class="
                font-header font-bold
                text-white text-3xl
                lg:text-4xl
                tracking-wider
                block
              "
              v-html="sub.h1"
            >
            </span>
          </div>
          <form class="md:w-3/5 lg:w-1/2 flex pt-6 md:pt-0">
            <input
              type="email"
              id="email"
              :placeholder="sub.input_placeholder"
              class="
                w-2/3
                bg-white
                font-body font-medium
                text-neutrals-g03 text-sm
                sm:text-base
                px-3
                sm:px-6
                py-5
                rounded-l-lg
                border-none
                md:border
                border-white
              "
            />
            <button
              class="
                bg-gradient-to-r
                from-primary
                via-secondary
                to-primary
                w-1/3
                h-full
                border-2 border-white
                rounded-r-lg
                uppercase
                font-body font-medium
                text-white
                hover:text-purple
                transition
                duration-300
                text-xs
                sm:text-sm
                xl:text-base
                px-4
                py-5
                focus:outline-none
              "
            >
              {{ sub.button_text }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["sub"],
};
</script>

<style>
</style>