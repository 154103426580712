<template>
  <div>
    <Header
      v-if="www.logo"
      :logo="www.logo?.data.attributes.url"
      :menu="www.menu"
    />

    <div v-if="blog.topic">
      <div
        class="
          mt-20
          container
          flex flex-col
          xl:flex-row
          justify-between
          pt-16
          xl:pt-24
          pb-48
          lg:pb-40
        "
      >
        <div class="xl:w-2/3">
          <div>
            <h1
              class="
                font-body font-medium
                text-3xl
                xl:text-4xl
                text-neutrals-g03 text-left
                md:leading-loose
                lg:leading-loose
                xl:leading-loose
              "
            >
              {{ blog.topic }}
            </h1>
            <div class="flex flex-col sm:flex-row pb-4">
              <div
                class="
                  flex
                  justify-center
                  sm:justify-start
                  items-center
                  pt-3
                  sm:pt-0
                "
              >
                <span class="pr-5 block font-body text-xs text-neutrals-g02">
                  {{ mkdate(blog.publishedAt) }}</span
                >
              </div>
            </div>
            <div>
              <img
                :src="`https://create.072.sh${blog?.thumb.data.attributes.url}`"
                class="w-full rounded-xl"
                alt="post image"
              />
            </div>
          </div>
          <div class="prose prose max-w-none pt-10 text-2xl">
            <p>
              {{ blog.des }}
            </p>
          </div>

          <div>
            <div class="flex flex-wrap pt-8">
              <a
                href="/post"
                class="
                  font-body font-medium
                  text-sm text-secondary
                  hover:text-purple
                  px-3
                  py-1
                  border border-neutrals-g01
                  block
                  mr-2
                  mb-2
                "
                v-for="(k, index) in blog.keywords.split(',')"
                :key="index"
              >
                {{k}}
              </a>
            </div>

            <div
              class="
                flex flex-col
                sm:flex-row
                justify-between
                py-12
                border-b border-neutrals-l03
              "
            >
              <span
                class="
                  font-body font-medium
                  text-neutrals-g03 text-lg text-center
                  sm:text-left
                "
                >Share this Post:</span
              >
              <div class="flex justify-center md:justify-start mt-2 sm:mt-0">
                <a
                  href="http://www.facebook.com"
                  class="
                    border border-neutrals-g01
                    rounded-full
                    px-2
                    py-1
                    shadow
                    flex
                    items-center
                    justify-center
                    mr-3
                  "
                >
                  <i
                    class="
                      bx
                      bxl-facebook
                      text-secondary
                      hover:text-purple
                      text-xl
                    "
                  ></i>
                </a>

                <a
                  href="http://www.twitter.com"
                  class="
                    border border-neutrals-g01
                    rounded-full
                    px-2
                    py-1
                    shadow
                    flex
                    items-center
                    justify-center
                    mr-3
                  "
                >
                  <i
                    class="
                      bx
                      bxl-twitter
                      text-secondary
                      hover:text-purple
                      text-xl
                    "
                  ></i>
                </a>

                <a
                  href="http://www.linkedin.com"
                  class="
                    border border-neutrals-g01
                    rounded-full
                    px-2
                    py-1
                    shadow
                    flex
                    items-center
                    justify-center
                    mr-3
                  "
                >
                  <i
                    class="
                      bx
                      bxl-linkedin
                      text-secondary
                      hover:text-purple
                      text-xl
                    "
                  ></i>
                </a>
              </div>
            </div>
            <div class="flex justify-between border-b border-neutrals-l02 py-8">
              <a
                href=" / "
                class="
                  font-body font-medium
                  text-neutrals-g03 text-lg
                  flex
                  items-center
                  group
                "
                ><i
                  class="
                    bx bx-left-arrow-circle
                    text-secondary
                    group-hover:text-purple
                    text-3xl
                    pr-2
                  "
                ></i
                >Previous</a
              >
              <a
                href="/"
                class="
                  font-body font-medium
                  text-neutrals-g03 text-lg
                  flex
                  items-center
                  group
                "
                >Next<i
                  class="
                    bx bx-right-arrow-circle
                    text-secondary
                    group-hover:text-purple
                    text-3xl
                    pl-2
                  "
                ></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="
            w-full
            sm:w-3/5
            lg:w-2/5
            mx-auto
            xl:mx-0 xl:w-1/4
            group
            pt-20
            xl:pt-0
          "
        >
        
          <div class="pt-10">
            <h3
              class="
                font-body font-medium
                text-neutrals-g03 text-2xl text-center
                md:text-left
              "
            >
              Follow Us
            </h3>
            <div class="pt-2 flex justify-center md:justify-start">
              <img src="/assets/img/underline-blue.svg" alt="underline" />
            </div>
            <div class="flex justify-center md:justify-start pt-8">
              <a
                href="http://www.facebook.com"
                class="
                  border border-neutrals-g01
                  rounded-full
                  px-2
                  py-1
                  shadow
                  flex
                  items-center
                  justify-center
                  mr-3
                "
              >
                <i
                  class="
                    bx
                    bxl-facebook
                    text-secondary
                    hover:text-purple
                    text-xl
                  "
                ></i>
              </a>

              <a
                href="http://www.twitter.com"
                class="
                  border border-neutrals-g01
                  rounded-full
                  px-2
                  py-1
                  shadow
                  flex
                  items-center
                  justify-center
                  mr-3
                "
              >
                <i
                  class="
                    bx
                    bxl-twitter
                    text-secondary
                    hover:text-purple
                    text-xl
                  "
                ></i>
              </a>

              <a
                href="http://www.linkedin.com"
                class="
                  border border-neutrals-g01
                  rounded-full
                  px-2
                  py-1
                  shadow
                  flex
                  items-center
                  justify-center
                  mr-3
                "
              >
                <i
                  class="
                    bx
                    bxl-linkedin
                    text-secondary
                    hover:text-purple
                    text-xl
                  "
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Subscribe v-if="www.subscribe" :sub="www.subscribe" />
    <FooterDetail v-if="www.footer" :footer="www.footer" />
    <Footer v-if="www.footer" :footer="www.footer" />
  </div>
</template>

<script>
import Header from "../components/HeaderTop.vue";
import Subscribe from "../components/SubscribeBox.vue";
import FooterDetail from "../components/FooterDetail.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "Blog",
  components: { Header, Subscribe, Footer, FooterDetail },
  data() {
    return {
      www: {},
      blog: {},
      locale: "en",
    };
  },
  async beforeCreate() {
    try {
      let urlParams = new URLSearchParams(window.location.search);
      let locale =
        urlParams.has("locale") && urlParams.get("locale")
          ? urlParams.get("locale")
          : "en";
      this.locale = locale;
      const qs = require("qs");
      const queryScene = qs.stringify(
        {
          populate: [
            "logo",
            "menu",
            "hero",
            "blog",
            "hero.image",
            "subscribe",
            "footer.social",
            "footer.center_image",
            "footer.contact",
            "footer.link",
            "copyright",
            "feature.advantage",
            "feature.advantage.image",
            "pagedetail",
          ],
          locale: locale,
        },
        {
          encodeValuesOnly: true,
        }
      );
      const www = await axios.get(
        `https://create.072.sh/api/www?${queryScene}`
      );
      this.www = www.data.data.attributes;

      const queryBlogs = qs.stringify(
        {
          populate: ["*", "thumb"],
          locale: locale,
        },
        {
          encodeValuesOnly: true,
        }
      );
      const blog = await axios.get(
        `https://create.072.sh/api/blogs/${this.$route.params.id}?${queryBlogs}`
      );
      this.blog = blog?.data.data.attributes || {};
      const descEl = document.querySelector('head meta[name="description"]');
      const titleEl = document.querySelector("head title");
      const keywordEl = document.querySelector('head meta[name="keywords"]');
      descEl.setAttribute("content", this.blog.des);
      keywordEl.setAttribute("content", this.blog.keywords);
      titleEl.textContent = this.blog.topic;
    } catch (e) {
      this.$router.push("/404");
    }
  },
  methods: {
    mkdate: function (pdate = new Date()) {
      const edate = pdate.split("T")[0].split("-");
      const date = new Date(edate[0], edate[1], edate[2]);
      const result = date.toLocaleDateString(this.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return result;
    },
  },
};
</script>
