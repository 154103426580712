<template>
  <div
    class="
      bg-neutrals-l01
      pt-16
      lg:pt-24
      pb-36
      lg:pb-48
      relative
      overflow-hidden
    "
  >
    <div
      class="
        bg-cover bg-top bg-no-repeat
        w-76
        lg:w-100
        h-96
        lg:h-100
        xl:h-120
        2xl:h-132
        absolute
        top-0
        left-0
        -ml-40
        sm:-ml-32
        lg:-ml-48
        xl:-ml-40
        2xl:-ml-36
      "
      style="background-image: url(/assets/img/blog-figure.svg)"
    ></div>
    <div
      class="
        bg-cover bg-center bg-no-repeat
        w-128
        h-128
        absolute
        bottom-0
        md:top-0
        right-0
        -mr-56
        md:mt-32
        mb-72
        md:mb-0
      "
      style="background-image: url(/assets/img/blog-ellipse.svg)"
    ></div>
    <div class="container z-20 relative">
      <div class="text-center">
        <h4
          class="
            font-body font-medium
            text-primary text-base
            md:text-lg
            uppercase
          "
        >
          {{ blog.h2 }}
        </h4>
        <h2
          class="
            font-body font-bold
            text-neutrals-g03 text-3xl
            sm:text-4xl
            lg:text-5xl
            tracking-wider
          "
        >
          {{ blog.h1 }}
        </h2>
        <div class="pt-4 md:pt-6">
          <img
            src="/assets/img/underline-blue.svg"
            class="mx-auto"
            alt="underline"
          />
        </div>
      </div>
      <div class="w-full pt-10 lg:pt-16">
        <div class="posts-slider">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
              <li class="glide__slide" v-for="(v, index) in blogs" :key="index">
                <div class="pb-6 md:pb-0 text-center md:text-left">
                  <a :href="'https://blog.072.sh/post/' + v.id"
                    ><img
                      class="rounded-xl"
                      :src="`https://create.072.sh${v.attributes.thumb.data.attributes.url}`"
                  /></a>
                  <a
                    :href="'https://blog.072.sh/post/' + v.id"
                    class="
                      font-body font-medium
                      text-neutrals-g03
                      hover:text-purple
                      transition
                      duration-300
                      text-xl
                      tracking-wide
                      block
                      pt-6
                    "
                  >
                    {{ v.attributes.topic }}</a
                  >
                  <p class="font-body text-neutrals-g03 text-sm pt-3 lg:pt-5">
                    {{ v.attributes.des }}
                  </p>
                  <a
                    :href="'https://blog.072.sh/post/' + v.id"
                    class="
                      inline-block
                      mt-6
                      px-8
                      py-4
                      bg-white
                      font-body
                      text-neutrals-g03
                      hover:text-purple
                      text-sm
                      rounded-full
                      shadow
                      hover:shadow-md
                      border border-primary
                      hover:border-purple
                      transition
                      duration-300
                    "
                  >
                    {{ blog.button_text }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div class="glide__arrows" data-glide-el="controls">
            <button
              class="
                glide__arrow glide__arrow--left
                bg-white
                border border-purple
                px-2
                py-1
                rounded-full
                -ml-10
              "
              data-glide-dir="<"
            >
              <i class="text-3xl text-purple bx bx-chevrons-left"></i>
            </button>
            <button
              class="
                glide__arrow glide__arrow--right
                bg-white
                border border-purple
                px-2
                py-1
                rounded-full
                -mr-10
              "
              data-glide-dir=">"
            >
              <i class="text-3xl text-purple bx bx-chevrons-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["blog", "blogs"],
  updated() {
    new Glide(".posts-slider", {
      type: "carousel",
      perView: 3,
      gap: 32,
      breakpoints: {
        1023: {
          perView: 2,
          gap: 16,
        },
        680: {
          perView: 1,
        },
      },
    }).mount();
  },
};
</script>

<style>
</style>