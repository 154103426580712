<template>
  <div>
    <Header
      v-if="www.logo"
      :logo="www.logo?.data.attributes.url"
      :menu="www.menu"
    />

    <div>
      <div class="pt-12 mt-20 pb-40 md:pb-28 container">
        <div
          class="
            bg-secondary
            py-10
            md:py-16
            rounded-xl
            flex
            items-center
            flex-col
            md:flex-row
            justify-between
            px-6
            sm:px-12
          "
        >
          <div class="md:w-2/5 text-center md:text-left">
            <h1
              class="
                font-body font-bold
                text-7xl
                sm:text-8xl
                lg:text-9xl
                text-white
              "
            >
              404
            </h1>
            <p class="font-body text-white lg:text-2xl leading-tight pt-5">
              The page you are looking for doesn't exist.
            </p>
            <a
              href="/"
              class="
                py-4
                md:py-5
                px-8
                lg:px-10
                bg-white
                font-body font-medium
                text-secondary
                hover:text-purple
                text-base
                lg:text-xl
                tracking-wider
                uppercase
                rounded-full
                text-center
                inline-block
                mt-6
                lg:mt-10
                shadow-md
                hover:shadow
                transition
                duration-300
                border border-white
                hover:border-purple
              "
              >Go back to home</a
            >
          </div>
          <div class="w-full sm:w-5/6 md:w-1/2 mt-8 md:mt-0">
            <img
              src="/assets/img/hero-illustration.svg"
              alt="illustration"
              class="w=full"
            />
          </div>
        </div>
      </div>
    </div>
    <Subscribe v-if="www.subscribe" :sub="www.subscribe" />
    <FooterDetail v-if="www.footer" :footer="www.footer" />
    <Footer v-if="www.footer" :footer="www.footer" />
  </div>
</template>

<script>
import Header from "../components/HeaderTop.vue";
import Subscribe from "../components/SubscribeBox.vue";
import FooterDetail from "../components/FooterDetail.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "Blog",
  components: { Header, Subscribe, Footer, FooterDetail },
  data() {
    return {
      www: {},
      blogMain: {},
      blogs: {},
      locale: "en",
    };
  },
  async beforeCreate() {
    let urlParams = new URLSearchParams(window.location.search);
    let locale =
      urlParams.has("locale") && urlParams.get("locale")
        ? urlParams.get("locale")
        : "en";
    this.locale = locale;
    const qs = require("qs");
    const queryScene = qs.stringify(
      {
        populate: [
          "logo",
          "menu",
          "hero",
          "blog",
          "hero.image",
          "subscribe",
          "footer.social",
          "footer.center_image",
          "footer.contact",
          "footer.link",
          "copyright",
          "feature.advantage",
          "feature.advantage.image",
          "pagedetail",
        ],
        locale: locale,
      },
      {
        encodeValuesOnly: true,
      }
    );
    const www = await axios.get(`https://create.072.sh/api/www?${queryScene}`);
    this.www = www.data.data.attributes;

    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector("head title");
    const keywordEl = document.querySelector('head meta[name="keywords"]');
    descEl.setAttribute("content", this.www.pagedetail.des);
    keywordEl.setAttribute("content", this.www.pagedetail.keywords);
    titleEl.textContent = "404 not found page - " + this.www.pagedetail.title;
  },
};
</script>
