<template>
  <!-- About Us -->

  <div class="relative overflow-hidden" id="about-us">
    <div
      class="
        bg-top bg-cover bg-no-repeat
        w-80
        h-80
        absolute
        bottom-0
        left-0
        -ml-40
        mb-12
      "
      style="background-image: url(/assets/img/about-ellipse.svg)"
    ></div>
    <div
      class="
        bg-top bg-cover bg-no-repeat
        w-56
        h-76
        absolute
        top-0
        right-0
        -mr-28
        md:mt-32
        lg:mt-0
      "
      style="background-image: url(/assets/img/about-figure.svg)"
    ></div>
    <div
      class="
        container
        pt-20
        sm:pt-24
        md:pt-16
        lg:pt-24
        sm:pb-16
        lg:pb-24
        flex flex-col
        lg:flex-row
        justify-between
        items-center
        z-20
        relative
      "
    >
      <div class="lg:w-2/5 text-center lg:text-left">
        <h4
          class="
            font-body font-medium
            text-primary text-base
            md:text-lg
            uppercase
          "
        >
          {{ about.h2 }}
        </h4>
        <h2
          class="
            font-body font-bold
            text-neutrals-g03 text-3xl
            sm:text-4xl
            lg:text-5xl
            tracking-wider
            leading-tight
          "
        >
          {{ about.h1 }}
        </h2>
        <div class="pt-4 md:pt-6 flex justify-center lg:justify-start">
          <img src="/assets/img/underline-blue.svg" alt="underline" />
        </div>
        <p
          class="font-body text-neutrals-g03 text-xl leading-loose pt-10"
          v-html="about.des"
        ></p>
        <button class="btn btn-primary" @click="goto()">
          {{ about.button_text }}
        </button>
      </div>
      <div class="w-full lg:w-1/2 relative mt-16">
        <div
          class="
            bg-primary
            w-84
            h-72
            sm:h-96
            xl:h-100
            mx-auto
            lg:mx-0 lg:ml-auto
          "
        >
          <img
            :src="`${URL_API}${about.image.data.attributes.url}`"
            class="border-0"
          />
        </div>
        <div
          class="
            absolute
            right-0
            top-0
            bg-center bg-cover bg-no-repeat
            w-84
            sm:w-120
            xl:w-132
            h-56
            sm:h-80
            xl:h-84
            mt-8
            mr-1
            sm:mr-16
            md:mr-32
            lg:mr-8
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      URL_API: "https://create.072.sh",
    };
  },
  props: ["about"],
  methods: {
    goto: function () {
      window.location.href = "https://blog.072.sh/post/1";
    },
  },
};
</script>

<style>
</style>